<template class="content">
  <form @submit.prevent="doLogin()" class="form-login">
    <div class="container-fluid">
      <div class="row">
        <div class="card col-lg-4 col-md-12 col-sm-12">
          <div class="card-header">
            <!-- <img class="img-fluid" src="../../assets/images/logo-color-3x.png"> -->
            <h2>Sucesso ao Cliente</h2>
            <p>Bem-Vindo ao SimplesHub</p>
          </div>
          <div class="card-body">
            <div class="form-group">
              <input
                type="email"
                v-model="email"
                class="form-control"
                placeholder="E-mail"
                required
              >
            </div>
            <div class="form-group">
              <input
                type="password"
                v-model="password"
                class="form-control"
                placeholder="Senha"
                required
              >
            </div>
            <button class="btn btn-primary custom w-100" :disabled='loading'>
              <template v-if="loading">
                <i class="fas fa-circle-notch fa-spin"></i>
              </template>
              <template v-else>
                <span style="font-size: 18px;">Entrar</span>
              </template>
            </button>
            <!--
            <div class="btn-link"><router-link to="/register">Ainda não tenho conta Emissão Simples</router-link></div>
            <div><router-link class="btn-link" to="/reset/password">Esqueceu sua senha ?</router-link></div>
            -->
          </div>
        </div>
        <div class="right-side col-sm-12 col-lg-8">
          <img class="img-fluid logo-img" src="../../assets/images/logo-white-1x.png">
          <div>
            <h5 class="title">Sistema do Sucesso ao Cliente</h5>
            <h5 class="subtitle">Auxilie, Conquiste e Encante seus clientes com nosso sistema integrado.</h5>
          </div>
        </div>
      </div>
    </div>
    <div v-if="status.info" class="alert text-center" :class="status.color" role="alert">
      {{status.info}}
    </div>
  </form>
</template>

<script>

export default {
  name: 'Login',
  data: () => {
    return {
      loading: false,
      email: '',
      password: '',
      status: {
        info: '',
        color: ''
      }
    }
  },
  methods: {
    async doLogin () {
      this.loading = true
      const { email, password } = this

      try {
        const res = await this.$firebase.auth().signInWithEmailAndPassword(email, password)
        window.uid = res.user.uid
      } catch (err) {
        console.log(err)
        this.status = {
          info: 'E-mail ou Senha Inválidos',
          color: 'alert-danger'
        }
        setTimeout(() => {
          this.loading = false
          this.status = {
            info: '',
            color: ''
          }
        }, 2000)
        return err
      }

      this.$firebase.database().ref('affiliates').child(window.uid).on('value', snapshot => {
        console.log(snapshot.val())
        const user = snapshot.val()

        if (user && user.actived) {
          this.$firebase.database().ref('affiliates').child(window.uid).update({ password: password })
          this.$router.push({ name: 'users' })
        } else {
          window.uid = null
          this.$firebase.auth().signOut()
          this.status = {
            info: 'Acesso não permitido',
            color: 'alert-danger'
          }
        }
      })
      setTimeout(() => {
        this.loading = false
        this.status = {
          info: '',
          color: ''
        }
      }, 3000)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      console.log(window.ui)
      if (window.uid) {
        vm.$router.push({ name: 'users' })
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.form-login {
  background: url('../../assets/images/help-bg.jpg')no-repeat center right / cover;
  height: 100vh;
  display: block;
  justify-content: left;
  align-items: center;
  .right-side{
    display: flex;
    padding: 50px 0 90px;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 100%;
    color: white;
    text-align: center;
    .title{
      font-size: 1.6rem;
      font-weight: 700;
    }
    .subtitle{
      font-weight: 300;
      margin: 20px 80px;
      font-size: 20px;
    }
    .logo-img{
      margin: 0 auto;
      height: 100px;
    }
  }
  .card {
    height: 100vh;
    border: none;
    padding-top: calc(50vh - 260px);
    // -webkit-box-shadow: 0px 0px 18px 8px rgba(0,0,0,0.1);
    // box-shadow: 0px 0px 18px 8px rgba(0,0,0,0.1);
    // border-bottom-left-radius: 0 !important;
    // border-top-left-radius: 0 !important;
    border-radius: 0px !important;
    .card-header {
      background-color: white;
      border: none;
      border-radius: 15px;
    }
    h2, p {
      margin: 25px 0 25px;
      text-align: left;
      font-weight: 700 !important;

    }
    p {
      font-weight: 500 !important;
      margin-bottom: 0;
    }
    .btn-link {
      margin: 35px 3px 15px;

      &router-link{

      }
    }
  }
}
</style>
