<template>
  <div id="app">
    <base-spinner/>
    <div v-if="showLayout" class="d-flex flex-row w-100 h-100">
      <layout-navigation/>
      <router-view/>
    </div>
    <router-view v-else/>
  </div>
</template>

<script>
import BaseSpinner from './components/global/BaseSpinner'
import LayoutNavigation from './components/layout/LayoutNavigation.vue';

export default {
  name: 'App',
  components: {
    BaseSpinner,
    LayoutNavigation,
  },
  computed: {
    showLayout() {
      const currentPath = this.$route.path;
      const isEvaluationRoute = currentPath.startsWith('/evaluation');
      return !isEvaluationRoute && currentPath !== '/' && currentPath !== '/login';
    },
  },
  mounted () {
    setTimeout(() => {
      this.$root.$emit('Spinner::hide')
    }, 500)
  }
}
</script>

<style lang="scss">
#app{
  min-height: 100vh !important;
  height: 100vh !important;
  background-color: var(--background-gray);
}
</style>
