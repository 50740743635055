<template>
    <div :class="{faded: route && route === 'taskHub'}">
      <router-link class="nav-link" :class="[isRouteActive ? 'active' : '', barClosed ? 'shrink-container' :  'expand-container', route === 'logout' ? 'logout' : '']" :to="{ name: route }">
        <slot></slot>
        <p class="text-link" :class="[barClosed ? 'hiding-text' :  'show-text']">
          {{ routeLabel }}
        </p>
      </router-link>
    </div>
  </template>
  
  <script>
  
  export default {
    props: ['route', 'routeLabel', 'barClosed', 'disable'],
    computed: {
      isRouteActive () {
        return this.$route.name === this.route
      }
    }
    // methods: {
    //   async manageLogOut () {
    //     if (this.route === 'logout') {
    //       this.$root.$emit('Spinner::show')
    //       this.$cookie.delete('currentProfile')
    //       await this.$firebase.auth().signOut()
    //       setTimeout(() => {
    //         this.$router.push({ name: 'login' })
    //         this.$root.$emit('Spinner::hide')
    //       }, 1500)
    //     }
    //   }
    // }
  }
  </script>
  
  <style lang="scss" scoped>
  
  .faded {
    opacity: 0.7;
  }
  
  .nav-link {
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #475569;
    margin: 0px;
    margin-left: 11px;
    margin-right: 11px !important;
    width: 188px;
    transition: all .2s ease-in-out;
  
    &.shrink-container {
      animation: shrinkContainer 0.6s forwards;
    }
  
    &.expand-container {
      animation: expand 0.6s forwards;
    }
    .text-link {
      position: absolute;
      top: 13px;
      left: 52px;
      font-size: 14px;
      font-weight: 400;
      color: #475569;
  
      &.hiding-text {
        animation: hidingText 0.6s forwards;
      }
      &.show-text {
        animation: showText 0.6s forwards;
      }
    }
    &.active {
      background-color: var(--surface-brand-heavy-light);
      outline: 1px solid var(--stroke-brand);
      transition: all .3s;
  
      p {
            color: var(--text-color-brand-contrast);
  
            font-weight: 500;
  
      }
  
      svg {
        path {
          stroke: #046e60;
        }
      }
    }
    &:hover {
      background-color: var(--surface-brand-heavy-light);
      color: var(--text-color-brand);
  
      svg {
        path {
          stroke: #046e60;
        }
      }
  
      .logout {
        svg {
          path {
            stroke: #EF4444 !important;
          }
        }
        background-color: rgba(232, 121, 121, .09) !important;
        color: var(--red-light) !important;
      }
    }
    &.logout {
      &:hover {
        svg {
          path {
            stroke: #EF4444 !important;
          }
        }
        background-color: rgba(232, 121, 121, .09) !important;
        color: var(--red-light) !important;
      }
    }
    &.disabled {
      color: #c1c1c1 !important;
      svg {
        fill: #c1c1c1;
        path {
          stroke: #c1c1c1;
        }
      }
    }
    svg {
      margin-right: 12px;
      transform: translate(0, -1px);
    }
    .new-box {
      display: inline-flex;
      padding: 2px 6px;
      margin-left: 4px;
      margin-bottom: 1px;
      justify-content: center;
      align-items: center;
  
      color: var(--text-brand);
      /* small-title */
      font-family: Poppins;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      /* 123.077% */
      letter-spacing: -0.13px;
      border-radius: 4px;
      border: 1px solid var(--stroke-brand);
      background: var(--surface-brand-heavy-light);
      transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
  
      &.dense {
        padding: 2px 4px;
        font-size: 11px;
        line-height: 14px;
      }
  
      &.compact {
        padding: 2px 4px;
        font-size: 11px;
        line-height: 14px;
      }
      &.checkup360 {
        font-weight: 500;
        padding: 2px 4px;
        font-size: 11px;
        line-height: 14px;
        // background-color: var(--surface-contrast);
        // color: var(--text-invert);
        // border: 1px solid var(--surface-contrast);
      }
    }
  }
  
  .nav-link:focus {
    // box-shadow: 0px 0px 0px 3px var(--surface-brand-light);
  }
  .nav-link::selection {
    color: none;
    background: none;
  }
  .nav-link::-moz-selection {
    color: none;
    background: none;
  }
  @keyframes shrinkContainer {
    from {
      width: 208px;
      padding: 12px 16px;
    }
    to {
      padding: 12px;
      width: 48px;
    }
  }
  @keyframes expand {
    from {
      margin-right: 16px;
      padding: 12px;
      width: 48px;
    }
    to {
      width: 208px;
      padding: 12px 16px;
      margin-right: 11px;
    }
  }
  @keyframes hidingText {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
  @keyframes showText {
    0% {
      opacity: 0;
      display: none;
    }
    25% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.4;
    }
    75% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  </style>
  